import React from 'react'
import { useTranslation } from 'react-i18next';

const FooterLinks: React.FC<{className?: string}>=({className})=> {
    const { t } = useTranslation();
    const linksArray:{key:string,link:string,text:string}[]=t("footer",{returnObjects:true})
    
    
    return (
        <ul className={`menu menu-gray-600 menu-hover-primary fw-bold order-1 ${className}`}>
            {linksArray && Array.isArray(linksArray)?linksArray.map((linkObj,index)=>//set footer links from backend
             { 
               let className=`menu-link ${!index?'ps-0':'ps-3'}`
               return <li key={linkObj.key} className="menu-item">
              <a href={linkObj.link} rel="noreferrer" target="_blank" className={className}>
              {linkObj.text}
              </a>
            </li>}
            ):null}
            
        </ul>
    )
}

export default FooterLinks
