import React from 'react'
type Props={
    link:string,
    title:string,
    text:string,
    color:string,
    col:number
}
const MenuItem:React.FC<Props>=({ 
    link,
    title,
    text,
    color,
    col
}) =>{
    return (
        <div className={`col-sm-${col}`}>
                  <a
                  rel="noreferrer"
                  target="_blank"
                    href={link}
                    className={`card card-custom bg-light-${color} hoverable min-h-125px shadow-none mb-5`}
                  >
                    <div className="card-body d-flex flex-column flex-center">
                      <h3 className="fs-3 mb-2 text-dark fw-bolder">
                        {title}
                      </h3>
                      <p className="mb-0 text-gray-600">{text}</p>
                    </div>
                  </a>
       </div>
       
    )
}

export default MenuItem
