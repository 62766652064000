import React from "react"
import { useCallback } from "react";
import { useState } from "react";

const initialContext={
    currentCardCurrencyCode:"",

    updateCurrentCardCurrency:(currencyCode: string) => {
    }
}

export const currenciesContext=React.createContext(initialContext);
    
    
const UserContextProvider: React.FC= (props) => {
   
    const [cardCurrency,setCardCurrency]=useState("");
  
    const updateCurrentCardCurrency=useCallback( (currencyCode:string)=>{
        setCardCurrency(currencyCode)
    },[])
    let contextValue={
        currentCardCurrencyCode:cardCurrency,
        updateCurrentCardCurrency
    }
    return <currenciesContext.Provider value={contextValue}>
        {props.children}
    </currenciesContext.Provider>
}

export default UserContextProvider; 