import React from 'react'
import { useTranslation } from 'react-i18next';

const Spiner=()=> {
    const {t}=useTranslation();
    return (
      <div className="text-center">
        <div className="spinner-border" style={{width: "3rem", height: "3rem"}} role="status">
        <span className="visually-hidden">{t("loading")}</span>
      </div>
      </div>
    )
}

export default Spiner
