

import Currencies from 'final-project/collections/Currencies.json';
import { useTranslation } from 'react-i18next';


const Currency=(currCode:string) => {
  
    
    const {t}= useTranslation();
    const currency= Currencies.find(curr=>curr.code===currCode);//currCtx.currenciesObj[`curr${currId}`]
     
    if(!!currency){//from where to load the icon: symbol or name
        if(currency.symbol)
            return currency.symbol
        else
            return ` ${t(currency.name)}`
    }
      else
       return null
}
export default Currency
