/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Chart, { ChartConfiguration } from "chart.js";


import {  toAbsoluteUrl } from "_start/helpers";
import { getCSSVariableValue } from "_start/assets/ts/_utils";
import { getCategories } from "final-project/crud/CardCRUD";
import { PeriodModel } from "final-project/api models/PeriodModel";
import { useGlobalVariables } from "final-project/helpers/hooks/useGlobalVariablests";
import CategoryModel from "final-project/api models/CategoryModel";
import { useTranslation } from "react-i18next";
import percentRound from "percent-round";
import ShowError from "final-project/helpers/components/ShowError";
import Spiner from "final-project/helpers/components/Spiner";


type Props = {
  className: string;
  innerPadding?: string;
  activeCardId: number
  period:PeriodModel,
  categoriesArray:CategoryModel[],
  transNum:number,
  isLoaded:boolean,
  transactionsNumNeto?:number,
   setCategoriesInObj:(categoriesData:CategoryModel[],transNum:number)=>void,
   setIsPeriodLoadedArray:(isLoaded:boolean,isFullData:boolean)=>void
};
// type categoryType={
//   categoryId:number,
//   categoryNum:number,
//   categoryName?:string,
//   categoryIcon?:string
// }
const StatsWidget1: React.FC<Props> = ({ 
  className,
  innerPadding = "",
  activeCardId,
  period,
  categoriesArray,
  transNum,
  isLoaded,
  transactionsNumNeto,
   setCategoriesInObj,
   setIsPeriodLoadedArray
}) => {
  const [error,setError]=useState<string | null>(null)
  const [mostPopularCategoryArray,setMostPopularCategoryArray]=useState<CategoryModel[]>([]);
  const [categoriesPrecent,setCategoriesPrecent]=useState<number[]>([])
  const [transactionsNum,setTransactionsNumber]=useState(0);
  const color1 = getCSSVariableValue("--bs-success");
  const color2 = getCSSVariableValue("--bs-warning");
  const color3 = getCSSVariableValue("--bs-primary");
  const color4 = getCSSVariableValue("--bs-info");
  const color5 = getCSSVariableValue("--bs-dark");
  const color6 = getCSSVariableValue("--bs-secondary");
  const colors=[color1,color2,color3,color4,color5,color6]
  const colorsArray=["bg-success","bg-warning","bg-primary","bg-info","bg-dark","bg-secondary"]
  const {token,lng}=useGlobalVariables()
  const {t}=useTranslation();
  const numtoShow=2//num of categories per row
  const bg=transactionsNum?`url('${toAbsoluteUrl(
    "/media/svg/illustrations/bg-1.svg"
  )}')`:""
  const [isLoading,setIsLoading]=useState(false);
  useEffect(() => {
    error && setError(null)
    if(activeCardId && token && period.nameForCategory) 
    {
      setIsLoading(true)
      if(!isLoaded){
      token && getCategories(activeCardId,token,period.nameForCategory,lng)//,transactionsNumNeto
    .then(({data})=>{
      let categories =data.statisticsData?.categories || [];
      let transactionsNumber=data.statisticsData?.transactionsNumber || 0;
      
      //  setTransactionsNumber(transactionsNumber);
      //  setMostPopularCategoryArray([...categories]);
      //  setCategoriesPrecent(percentRound(categories.map(cat=>cat.percentage)));
      setCategoriesInObj(categories,transactionsNumber);
    })
    .catch(err=>{
      console.log(err.response?.data.message);
      setError(err.response?.data.message || t("somthing went"))
      setIsPeriodLoadedArray(false,false);
    })
    .finally(()=>{
      setIsLoading(false);
    })
    }
    else{
      setMostPopularCategoryArray([...categoriesArray])
      setTransactionsNumber(transNum);
      setCategoriesPrecent(percentRound(categoriesArray.map(cat=>cat.percentage)));
      setIsLoading(false);
    }
  }
  },[period,period.nameForCategory,token,categoriesArray[0]?.name,transNum,isLoaded])
  useEffect(() => {
    if (transactionsNum && mostPopularCategoryArray.length) {
      const element = document.getElementById(
        "kt_stats_widget_1_chart"
      ) as HTMLCanvasElement;
      if (!element) {
        return;
      }
      const options = getChartOptions(
        mostPopularCategoryArray.map(category => {
          return Math.round((category.percentage / 100) * transactionsNum)
        }),//data points
        mostPopularCategoryArray.map(category => { return category.name }),//labels
         colors
         
        );
      //   chart?.data.datasets?.forEach((dataset) => {
      //     dataset.data?.fill(mostPopularCategoryArray.map(category=>{
      //       return Math.round((category.percentage/100)*transactionsNum)}))
      // });
      //   chart?.data.labels?.fill(mostPopularCategoryArray.map(category=>{
      //     return Math.round((category.percentage/100)*transactionsNum)}))
      const ctx = element.getContext("2d");
      let myDoughnut: Chart | null;
      if (ctx) {
        myDoughnut = new Chart(ctx, options);

      }

      return () => {
        if (myDoughnut) {
          myDoughnut.destroy();
        }
      };
    }
  }, [mostPopularCategoryArray]);

  return (
    <div className={`${className}`}>
      {/* begin::Header */}
     <div
        className={`card-header align-items-center border-0 mt-5 ${innerPadding}`}
      >
        <h3 className="card-title align-items-start flex-column">
          <span className="fw-bolder text-dark fs-3">{t("cardUses")}</span>
          <span className="text-muted mt-2 fw-bold fs-6">
            {t("numOfTransInPeriod", {
              trans_num: transactionsNum,
              transactions: t(`transaction${transactionsNum !== 1 ? 's' : ''}`),
              period_name: period.name
            }
            )}
            </span>
        </h3>
      </div>
      
      {/* end::Header */}

      {/* begin::Body */}
      {!error?<div className="card-body pt-12">
        {/* begin::Chart */}
        {!isLoading? <>
        {<div
          className="d-flex flex-center position-relative bgi-no-repeat bgi-size-contain bgi-position-x-center bgi-position-y-center h-175px"
          style={{
             backgroundImage: bg,
          }}
        >
          {transactionsNum?<div className="fw-bolder fs-1 text-gray-800 position-absolute">
          {transactionsNum}
          </div>:<div className="position-absolute">
          {t("noCategories")}
          </div>}
         {<canvas id="kt_stats_widget_1_chart"></canvas>}
        </div>}
        
        {/* end::Chart */}

        {/* begin::Items */} {
        //     categories.map((cat,ind) => 
        // <div key={`cat${ind}`} className="d-flex justify-content-around px-10 pt-18">
        //  {cat.map((category,index)=>
        //        <div key={`cat${index}`} className={`col-${Math.floor(12/numtoShow)}`}>
        //       <span className="fw-bolder text-gray-800">{category.percentage.toFixed(2)}% {category.name}</span>
        //       <span className={`${colorsArray[ind*numtoShow+index]} w-25px h-5px d-block rounded mt-1`}></span>
        //     </div>)
         <div  className={`d-flex ${mostPopularCategoryArray.length===1?'justify-content-center text-center':null} row pt-18`}>
       { mostPopularCategoryArray.map((category,index)=>
               <div key={`cat${index}`} className={`col-${Math.floor(12/numtoShow)} py-3`}>
              <span className="fw-bolder text-gray-800">{categoriesPrecent[index]}% {t(category.name)}</span>
              <span className={`${colorsArray[index]} w-25px h-5px d-block rounded mt-1 ${mostPopularCategoryArray.length===1?'m-auto':null}`}></span>
            </div>)}
        </div> }
        </>:<Spiner/>}
          
        {/* end::Items */}
      </div>:<div className="px-lg-12 px-sm-5 px-2"><ShowError errorMsg={error}/></div>}
      {/* end: Card Body */}
    </div>
  );
}

export default  React.memo(StatsWidget1) ;

function getChartOptions(data:number[],labels:string[],colors:string[]) {
  const tooltipBgColor = getCSSVariableValue("--bs-gray-200");
  const tooltipColor = getCSSVariableValue("--bs-gray-800");
  const options: ChartConfiguration = {
    type: "doughnut",
    data: {
      datasets: [
        {
          data: data,//[30, 40, 25],
          backgroundColor: colors,
        },
      ],
      labels: labels,//["Angular", "CSS", "HTML"],
    },
    options: {
      cutoutPercentage: 75,
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
        position: "top",
      },
      title: {
        display: false,
        text: "Technology",
      },
      animation: {
        animateScale: true,
        animateRotate: true,
      },
      tooltips: {
        enabled: true,
        intersect: false,
        mode: "nearest",
        bodySpacing: 5,
        yPadding: 10,
        xPadding: 10,
        caretPadding: 0,
        displayColors: false,
        backgroundColor: tooltipBgColor,
        bodyFontColor: tooltipColor,
        cornerRadius: 4,
        footerSpacing: 0,
        titleSpacing: 0,
      },
    },
  };
  return options;
}

// function randomScalingFactor() {
//   return Math.round(Math.random() * 100);
// }
