/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useRef, useState } from "react";
import ApexCharts, { ApexOptions } from "apexcharts";
import { getCSS, getCSSVariableValue } from "_start/assets/ts/_utils";
import PeriodButton from "final-project/app/components/Card Uses/PeriodButton";

import { PeriodModel } from "final-project/api models/PeriodModel";
import { TransactionModel } from "final-project/api models/TransactionModel";
import TransactionTable from "final-project/app/components/Card Activity/TransactionTable";
import { useTranslation } from "react-i18next";
import DailyBalanceModel from "final-project/api models/DailyBalanceModel";
import moment from "moment";
import { currenciesContext } from "final-project/context/CurrenciesProvider";
import Currencies from 'final-project/collections/Currencies.json';
import apexcharts from "apexcharts";
import Spiner from "final-project/helpers/components/Spiner";
import ShowError from "final-project/helpers/components/ShowError";


type Props = {
  className: string,
  period:PeriodModel,
  setPeriodObj:(period:PeriodModel)=>void,
   trans:TransactionModel[],
   balanceArray:DailyBalanceModel[]
   periodsArray:PeriodModel[],
   showMoreTrans:()=>void,
   loadMore:boolean,
   isLoading:undefined | "trans" | "balance",
   balanceError:string | null,
  transError:string | null
   
};

const MixedWidget1: React.FC<Props> = ({ 
  className,
  setPeriodObj,
  periodsArray,
  period,
  balanceArray,
  trans,
  showMoreTrans,
  loadMore,
  isLoading,
  balanceError,
  transError
 }) => {
  
  
  const chart1Ref = useRef<HTMLDivElement | null>(null);
  const {t}=useTranslation();
  const {currentCardCurrencyCode}=useContext(currenciesContext);
  const  curr= Currencies.find(curr=>curr.code===currentCardCurrencyCode);
  const currSymbol=!!curr?.symbol?curr?.symbol:curr?.name
  const [chart1,setChart1]=useState<apexcharts | undefined>(undefined);
  const noData=t("noTransactionsFound");
  const arrayForChart=balanceArray.map(balance=>
    {
      return {
        x:moment(balance.date).format("DD/MM/YYYY"),
        y:balance.balance
      }
    }
  )
  const name=t("balance")
  
  useEffect(() => {//set the chart when tje component did mount
    if (!chart1Ref.current) {
      return;
    }
     balanceArray.sort((a,b)=>new Date(a.date)>new Date(b.date)?1:-1)
    const heigh1 = parseInt(getCSS(chart1Ref.current, "height"));
     setChart1(new ApexCharts(chart1Ref.current, chart1Options(heigh1,arrayForChart ,name,currSymbol,period.color,noData)))//,balanceArray.map(balance=>balance.balance)
     return () => {
      if (chart1) {
        chart1.destroy();
      }
    };
  }, [chart1Ref,currSymbol]);
  useEffect(() => {//render chart when its update
    if (chart1) {
      chart1.render();
    }
  },[chart1]);
  useEffect(() => {//update chart when data updates
    chart1?.updateSeries([
     {data: arrayForChart,name:name}
    ]);
    chart1?.updateOptions(
      {
        stroke: 
        {
          curve: "smooth",
          show: true,
          width: 3,
          colors: [getCSSVariableValue(`--bs-${period.color}`) ,""],
      
        },
        colors: [getCSSVariableValue(`--bs-${period.color}`)],
        markers:
        {
          // colors: [getCSSVariableValue("--bs-light-primary")],
            strokeColors: [getCSSVariableValue(`--bs-${period.color}`)],
            strokeWidth: 3,
        },
      },true,true,false);
   
    
   },[trans,balanceArray])

 
   
  return (
    <div className={`${className}`}>
      {/* begin::Header */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bolder text-dark fs-3">
            {t("cardActivity")}
          </span>
          <span className="text-muted mt-2 fw-bold fs-6">
           {trans.length} {t(`transaction${trans.length!==1?'s':''}`)} 
          </span>
        </h3>
        <div className="card-toolbar">
          <ul className="nav nav-pills nav-pills-sm nav-light">
            {/* all the period buttons */}
            {periodsArray.map(per=>
            <PeriodButton 
            period={per}
            clickHandler={setPeriodObj}
            key={`per${per.id}`}
            isActive={per.id===period.id}
            
            >
              {per.name}
            </PeriodButton>)}
          </ul>
        </div>
      </div>
      {/*end::Header */}

      {/*begin::Body */}
      <div className="card-body p-0 pb-13 mt-n3">
        <div className="tab-content mt-5 px-lg-12 px-sm-5 px-2" id="myTabTables1">
          {/*begin::Tap pane */}
          <div
            className="tab-pane fade show active"
            id="kt_tab_pane_1_1"
            role="tabpanel"
            aria-labelledby="kt_tab_pane_1_1"
          >
          <div className="d-flex flex-column">
              {/*begin::Chart */}
                {<div className="flex-grow-1">
               {/* {!!trans.length?  */}
               <div
                  ref={chart1Ref}
                  id="kt_mixed_widget_1_chart"
                  style={{ height: "150px" }}
                ></div>
              </div>}
              {balanceError?<ShowError errorMsg={balanceError || t("somthing went")}/>:null}
              {/*end::Chart */}

              {/*begin::Items */}
              <div className="mt-5 ">
                {/*begin::Item */}

                {/* all the period transactions */}
                {!transError? trans && <TransactionTable 
                isLoading={isLoading==="trans"}
                trans={trans} 
                color={period.color}
                showMoreTrans={showMoreTrans}
                loadMore={loadMore}
                periodId={period.id}/>:
                <ShowError errorMsg={transError || t("somthing went")}/>}
                
               
              </div>
              {/*end::Widget Items */}
            </div>
          </div>
          {/*end::Tap pane */}

        
        </div>
      </div>
    </div>
  );
};

const chart1Options = (height: string | number | undefined,data:{x:any,y:any}[],name:string,currentCurrency:string | undefined,color:string,noData:string): ApexOptions => {
  return {
    series: [
      {
        name: name,
        data: data
      },
    ],
    chart: {
      id:"myChart",
      fontFamily: "inherit",
      type: "area",
      height: height,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 3,
      colors: [getCSSVariableValue(`--bs-${color}`) ,""],
    },
    xaxis: {
      //categories:categories,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: "#A1A5B7",
          fontSize: "12px",
        },
      },
      crosshairs: {
        show: false,
        position: "front",
        stroke: {
          color: getCSSVariableValue(`--bs-${color}`),
          width: 1,
          dashArray: 3,
        },
      },
      // tooltip: {
      //   enabled: true,
      //   formatter: undefined,
      //   offsetY: 0,
      //   style: {
      //     fontSize: "12px",
      //   },
      // },
    },
    yaxis: {
      labels: {
        show: false,
        style: {
          colors: "#A1A5B7",
          fontSize: "12px",
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
      },
      y: {
        formatter: function (val) {
        
          if(currentCurrency)
          return val+currentCurrency
          else
          return  val.toString();
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.6,
        stops: [30, 100],
      },
      // opacity: 1
    },
    colors: [getCSSVariableValue(`--bs-${color}`)],
    markers: {
     // colors: [getCSSVariableValue("--bs-light-primary")],
      strokeColors: [getCSSVariableValue(`--bs-${color}`)],
      strokeWidth: 3,
    },
    noData: {
      text: noData,
      align: 'center',
      verticalAlign: 'middle',
      // offsetX: 0,
      // offsetY: 0,
      // style: {
      //   color: undefined,
      //   fontSize: '14px',
      //   fontFamily: undefined
      // }
    }
  };
};



export default React.memo(MixedWidget1)  ;
