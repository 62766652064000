import React, { useState } from 'react'

const TransperButton: React.FC<{className:string,clickHandler:()=>void}>=({className,clickHandler,children})=> {
    const [color,setColor]=useState("purple");
   
    return (
        <button 
         style={{color:color}}
        onMouseOver={e=>setColor("#c59fc5")}
        onMouseLeave={e=>setColor("purple")}
        className={`btn bg-transparent pe-auto purple btn-active-color-light-purple ${className}`}
        onClick={clickHandler}>{children}
        </button>
    )
}
export default TransperButton;