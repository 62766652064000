import React, { useEffect } from "react"
import { useState } from "react";

import { getProgramSetting } from "final-project/crud/programCRUD";
import { useGlobalVariables } from "final-project/helpers/hooks/useGlobalVariablests";

type setting={
    id: number,
    name: string,
    address: string,
    main_page_document_title: string,
    main_page_favicon: string,
    logo_img_on_login: string,
    bg_img_on_login: string,
    logo_img_on_shared_cards: string,
    card_main_logo_img: string,
    default_card_type_logo_img: string,
    transactionTypeOptions: 
      {
        id: number,
        program_id: number,
        label:string,
        options: {value: string}[]
        ,
        createdAt: string,
        updatedAt: string
      }[],    
}

const initialSettings:setting={
    id: 0,
    name: "",
    address: "",
    main_page_document_title: "",
    main_page_favicon: "",
    logo_img_on_login: "",
    bg_img_on_login: "",
    logo_img_on_shared_cards: "",
    card_main_logo_img: "",
    default_card_type_logo_img: "",
    transactionTypeOptions:[]   
};


export const programSettingContext=React.createContext(initialSettings);
    
    
const ProgramSettingProvider: React.FC= (props) => {
    const {lng}=useGlobalVariables();
    const [programSetting,setProgramSetting]=useState(initialSettings);
    useEffect(()=>{
        getProgramSetting(lng)
        .then(({data})=>{
            setProgramSetting({...data.settings})
        })
        .catch(err=>{
            console.log("error while getting program details", err);  
        })
    },[])
   
    return <programSettingContext.Provider value={programSetting}>
        {props.children}
    </programSettingContext.Provider>
}

export default ProgramSettingProvider; 