import moment from 'moment';
import React, {  useEffect, useMemo, useState } from 'react'

import { PeriodModel } from 'final-project/api models/PeriodModel';
import { TransactionModel } from 'final-project/api models/TransactionModel';
import {  getBalance, getPeriodCardTrans } from 'final-project/crud/CardCRUD';//
import { useGlobalVariables } from 'final-project/helpers/hooks/useGlobalVariablests';
import MixedWidget1  from 'final-project/app/components/Card Activity/CardActivity'
import  StatsWidget1  from 'final-project/app/components/Card Uses/CardUses'
import DailyBalanceModel from 'final-project/api models/DailyBalanceModel';
import { useTranslation } from 'react-i18next';
import { DailyBalanceObjectModel, PushAllCardsTransactionsFunModel, TransObjectModel } from 'final-project/api models/TransModel';
import { CategoriesObjectModel } from 'final-project/api models/CategoriesModel';
import CategoryModel from 'final-project/api models/CategoryModel';
import { useImmer } from 'use-immer';



type Props={
    activeCardId: number,
    prevCardId:number,
    CardTransactions:TransObjectModel,
    isPeriodLoadedArray:{isloaded:boolean,isFullData:boolean}[],
    dailyBlanceObj:DailyBalanceObjectModel,
    CategoriesObj:CategoriesObjectModel,
    pushAllCardsTransactions:PushAllCardsTransactionsFunModel
}

const CardTransManager: React.FC<Props>=(
    {
        activeCardId,
        prevCardId,
        CardTransactions,
        isPeriodLoadedArray,
        dailyBlanceObj,
        CategoriesObj,
        pushAllCardsTransactions
        
    }
    )=> {
    const [balanceError,setBalanceError]=useState<string | null>(null)
    const [transError,setTransError]=useState<string | null>(null)
    const {token,lng}=useGlobalVariables();
    const {t}=useTranslation();
    const [isLoading, setIsLoading]=useState<undefined | "trans" | "balance">(undefined);
    const [transObj,setTransObj]=useImmer<TransObjectModel>({});//object of currant active card's transactions. key-the date. value an array of all the this date transactions
    const [categoryObj,setCategoryObj]=useImmer<CategoriesObjectModel>({})
    const [period,setPeriod]=useState<PeriodModel>({id:0,days:0,until:new Date(),name:"",nameForCategory:"",color:""});//the active period
    const [dailyBlance,setDailyBlance]=useState<{[key:string]:DailyBalanceModel[]}>({})
    const [skip,setSkip]=useState(0);
    const [isCurrentPeriodLoaded,setIsCurrentPeriodLoaded]=useState(false)
   const returnTransNum=Number(process.env.REACT_APP_RETURN_TRANSACTIONS_NUMBER) || 10;
  //  const transactionsNumNeto=skip+returnTransNum;
   
    
     // const [categoriesArray,setCategoriesArray]=useState<number[]>([]);
    //const [isAll,setIsAll]=useState(false)//is the active period is all the transactions
    const lastdayOfLastMonth=new Date();
    lastdayOfLastMonth.setDate(1);
    lastdayOfLastMonth.setHours(-1)
    const lastTransName=t("lastTransactions")
    const periodsArray=[
        {
          id:1,
         days:0,
         until:new Date(),
         name:lastTransName,
         nameForCategory:"last_transactions",
         color:"primary"
         
        },
        {
            id:2,
            days:7,
            until:new Date(),
            name:t("numOfDays",{num_of_days:7}),
            nameForCategory:"7_days",
            color:"warning"
          },
        {
          id:3,
          days:30,
          until:new Date(),
          name:t("numOfDays",{num_of_days:30}),
          nameForCategory:"30_days",
          color:"info"
        },
        // {
        //     id:5,
        //     days:0,
        //     until:new Date(),
        //     name:t("all"),
        //     nameForCategory:"",
        //     color:"--bs-info"

        // }
      ] 
     const [isPeriodLoaded,setIsPeriodLoaded]=useImmer<{isloaded:boolean,isFullData:boolean}[]>(new Array(periodsArray.length).fill({isloaded:false,isFullData:false}));//boolean array which symbolize the periods (true if has been loaded, false if not)
    const setPeriodObj=(periodObj:PeriodModel)=>{//fun to set the period
        setPeriod({...periodObj})
    } 
    let trans=transObj[period.name]?[...transObj[period.name]]:[];
      const fromAndTo=()=>{//calculate current from and to dates
        let from:string | undefined=undefined;
            let to:string | undefined=undefined;
            if(period.days)
            {
                const toDate=period.until;
                let fromDate=new Date(toDate)
                fromDate.setDate(toDate.getDate()-period.days);
                from=moment(fromDate).format('YYYY-MM-DD');
                to=moment(toDate).format('YYYY-MM-DD');
            }
        return {from,to};
    }
    const {from,to}=fromAndTo();
    //trans= trans.sort(sortTransByFullDate).slice(0,skip)
    useEffect(() => {//when the active card changing 
       
       
        setPeriodObj(periodsArray[0])//set the period to first period in the array
        if(!!prevCardId)//if there was active card before the current card
            pushAllCardsTransactions(prevCardId,transObj,isPeriodLoaded,dailyBlance,categoryObj)//push the prev active card data to the data objects in lightDashboardPage component
        if(CardTransactions){//if there is prev card transactions
            setTransObj(JSON.parse(JSON.stringify(CardTransactions)))//pull the transaction data of the current active card                
            setDailyBlance(JSON.parse(JSON.stringify(dailyBlanceObj)));
            setCategoryObj(JSON.parse(JSON.stringify(CategoriesObj)));
        }
        else{
            setTransObj({})//empty the transaction data object 
            setDailyBlance({});
            setCategoryObj({});
        }
        if(isPeriodLoadedArray && isPeriodLoadedArray.length>0){//if there is prev loaded periods array
            setIsPeriodLoaded([...isPeriodLoadedArray])//pull the loaded periods data of the current active card
       
        }
        else{
            setIsPeriodLoaded(new Array(periodsArray.length).fill({isloaded:false,isFullData:false}))
        }
    },[activeCardId])
    const setIsPeriodLoadedArray=(isLoaded: boolean,isFullData: boolean)=>{
        setIsPeriodLoaded(draft=>{//that this period to loaded in the loaded periods array
            draft[period.id-1].isloaded=isLoaded;
            draft[period.id-1].isFullData=isFullData
            // let arr=[...prev];
            // arr[period.id-1]={isloaded:isLoaded,isFullData:!isFullData};//set is loaded to true and isFull to the opposite of is there are more data
            // return arr;
        })

    }
    const setDataInTransObj=(data:TransactionModel[],periodIndex:number,moreTransactions:boolean)=>{//push the data to object .filter by dates
        
                   let obj:TransObjectModel={}
                obj[period.name]=JSON.parse(JSON.stringify(data))
                setTransObj(draft=>{
                    draft[period.name]?draft[period.name].push(...data): draft[period.name]=data
            //     let transdata=JSON.parse(JSON.stringify(prev))
            //     transdata[period.name] && obj[period.name].push(...transdata[period.name])
            // return {
                    
            //         ...transdata,
            //         ...obj
            //     }

        })
        // setIsPeriodLoaded(prev=>{//that this period to loaded in the loaded periods array
        //     let arr=[...prev];
        //     arr[periodIndex]={isloaded:true,isFullData:!moreTransactions};//set is loaded to true and isFull to the opposite of is there are more data
        //     return arr;
        // })
        setIsPeriodLoadedArray(true,!moreTransactions);
    }
    
    const setDailyBalanceInObj=(dailyBlanceData:DailyBalanceModel[])=>{//set current period daily balance
        let balanceObj:DailyBalanceObjectModel={}
        balanceObj[period.name]=JSON.parse(JSON.stringify(dailyBlanceData))
        setDailyBlance(prev=>{
            let balancedata=JSON.parse(JSON.stringify(prev))
            return{
                ...balancedata,
                ...balanceObj
            }
        })
    }
    const setCategoriesInObj=(categoriesData:CategoryModel[],transNum:number)=>{//set current period daily balance
        let catObj:CategoriesObjectModel={}
        catObj[period.name]={categories:JSON.parse(JSON.stringify(categoriesData)),transNum:transNum}
        setCategoryObj(draft=>{
            draft[period.name]={categories:categoriesData,transNum:transNum}
            // let catData=JSON.parse(JSON.stringify(prev))
            // return{
            //     ...catData,
            //     ...catObj
            // }
        })
        setIsCurrentPeriodLoaded(true)
    }
     useEffect(() => {//function called when the period changes
        balanceError && setBalanceError(null)
        transError && setTransError(null);
        if(activeCardId && period.id)
        {
            setIsCurrentPeriodLoaded(isPeriodLoaded[period.id-1].isloaded)
         if(!isPeriodLoaded[period.id-1].isloaded){//check if the period data didnt loaded from back
            let fromDate=from;
            let toDate=to
            if(period.nameForCategory){
                fromDate=undefined;
                toDate=undefined;
            }
            setSkip(0)
            setIsLoading("balance");
            loadData(0);
            loadBalance(fromDate,toDate,);//period.name===lastTransName?transactionsNumNeto:undefined
        }
        else
        {
            
            setSkip(transObj[period.name]?.length)
        }
    }
        
    },[period,period.id,period.nameForCategory])
   const loadBalance=(fromDate: string | undefined,toDate:string | undefined,transactionsNumNeto?:number)=>{
    token && getBalance(token,activeCardId,fromDate,toDate,period.nameForCategory,lng)//,transactionsNumNeto
    .then(({ data})=>{
        
        setDailyBalanceInObj(data.dailyBalance || []); 
    })
    .catch(err=>{
        console.log(err.response?.data.message) 
        setBalanceError(err.response?.data.message || t("somthing went"))
        setIsPeriodLoadedArray(false,false);
    })
    .finally(()=>{
        setIsLoading(undefined);
    })
   }
    const loadData=(skip:number)=>{//load trans from back
        
        setIsLoading("trans");
        let fromDate=from;
        let toDate=to
        if(period.nameForCategory){
            fromDate=undefined;
            toDate=undefined;
        }
        token && getPeriodCardTrans(token,activeCardId,skip,fromDate,toDate,period.nameForCategory,lng)
                .then(({ data})=>{
                  
                  
                    setDataInTransObj(data.transactionList || [],period.id-1,data.moreTransactions); 
                    setSkip(perv=>data.skip);
                    
                   //(data.skip===undefined || !data.transactionList?.length) && setIsLoadMore(false);
                })
                .catch(err=>{
                    console.log(err.response?.data.message) 
                    setTransError(err.response?.data.message || t("somthing went"))
                    setIsPeriodLoadedArray(false,false);
                })
                .finally(()=>{
                    setIsLoading(undefined);
                })
    }
   const showMoreTrans=()=>{//show more transaction button function
       if(!isPeriodLoaded[period.id-1].isFullData)//if the whole data didnt loaded from the back
       {
            loadData(skip);
            //
            // if(period.name===lastTransName){
            //     loadBalance(undefined,undefined,transactionsNumNeto);
            //     setIsCurrentPeriodLoaded(false);
            // }
            //
       }
       else//never happened because the if the data is loaded from back its show to the user
        setSkip(prev=>prev+returnTransNum);
    }
   const catArr=useMemo(() => categoryObj[period.name]?.categories??[], [activeCardId,period,categoryObj])
    return (
        
        <>
        {/* {isLoading?t("loading"): */}
        <> 
        <div className="col-xl-4">
          <StatsWidget1 
            className="card card-stretch mb-5 mb-xxl-8" 
            activeCardId={activeCardId}
            period={period}
            categoriesArray={catArr}
            transNum={categoryObj[period.name]?.transNum??0}
            isLoaded={isCurrentPeriodLoaded}
            setCategoriesInObj={setCategoriesInObj}
            setIsPeriodLoadedArray={setIsPeriodLoadedArray}
          />
          </div>
            <div className="col-xl-8">
         { <MixedWidget1 
            className="card card-stretch mb-5 mb-xxl-8"
            period={period}
            setPeriodObj={setPeriodObj}
            periodsArray={periodsArray}
            trans={trans}
            balanceArray={dailyBlance[period.name]?[...dailyBlance[period.name]]:[]}
            showMoreTrans={showMoreTrans}
            loadMore={(isPeriodLoaded[period.id-1]?!isPeriodLoaded[period.id-1].isFullData:true)}
            isLoading={isLoading}
            balanceError={balanceError}
            transError={transError}
          />}
          </div>
         
          </>
          {/* } */}
        </>
    )
}
    
export default React.memo(CardTransManager)
