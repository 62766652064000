//import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { Redirect, useLocation } from "react-router-dom";



const IsUser:React.FC<{}>=(props)=>{
    const [isValidLink,setIsValidLink]=useState<boolean>(true);
    const {search}=useLocation();
    const paramsObj=new URLSearchParams(search);
    const email= paramsObj.get("email");
    const token=paramsObj.get("token");
    useEffect(()=>{
        if(!email || (token!==null && token==="")){
            setIsValidLink(false);  
        }
    },[email,token])
    return <>
        {!!isValidLink
        ?props.children
        :<Redirect to="/auth/login"/> }
    </>
}
export default IsUser;