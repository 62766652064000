import axios from "axios";

import AccessTokenModel from "final-project/api models/AccessTokenModel";

import ResponseModel from "final-project/api models/ResponseModel";
import { UserModel } from "final-project/api models/UserModel";
import addLng from "final-project/crud/helperFunctions";


const API_URL = process.env.REACT_APP_BACKEND_URL || "api";


export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/get-user`;
export const LOGIN_URL = `${API_URL}/auth/login`;
export const REGISTER_URL = `${API_URL}/auth/register`;
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/initialize-password`;
export const DEFINE_PASSWORD_URL = `${API_URL}/auth/define-password`;
export const RESET_PASSWORD_URL = `${API_URL}/auth/save-password`;
export const IS_USER = `${API_URL}/auth/is-user`;
export const GET_USER = `${API_URL}/auth/get-user`;
export const SET_USER = `${API_URL}/auth/user`;


const recaptchaDisable = process.env.REACT_APP_RECAPTCHA_DISABLE?.toLowerCase() === "true";
type user = {
  id: number,
  program_id: string,
  email: string,
  password: string,
  is_archived: string | null,
  sharing_user_id: string
  token: string | null,
  createdAt: string,
  updatedAt: string,
  sharedUser: UserModel &
  {
    program_id: number,
    createdAt: string,
    updatedAt: string,
  },

}

const program_id = process.env.REACT_APP_PROGRAM_ID || "1";


export async function login(email: string, password: string, recaptcha_token: string, lng?: string) {


  const res = await axios.post<any>(LOGIN_URL + addLng(lng),
    {
      email,
      password,
      program_id,
      recaptcha_token: !recaptchaDisable ? recaptcha_token : undefined
    },
    {
      headers:
        { 'Content-Type': 'application/json' }
      ,
    }
  );
  return res
}



export function definePassword(
  email: string,
  password: string,
  confirm_password: string,
  token: string,
  recaptcha_token: string,
  lng?: string,
) {
  return axios.post<ResponseModel & { user: user } & { accessToken: AccessTokenModel | {} }>(DEFINE_PASSWORD_URL + addLng(lng), {
    email,
    password,
    confirm_password,
    program_id,
    token: token,
    recaptcha_token: !recaptchaDisable ? recaptcha_token : undefined
  });
}
export function resetPassword(
  email: string,
  password: string,
  confirm_password: string,
  token: string,
  recaptcha_token: string,

  lng?: string,
) {
  return axios.post<ResponseModel & { user: user } & { accessToken: AccessTokenModel | {} }>(RESET_PASSWORD_URL + addLng(lng), {
    email,
    password,
    confirm_password,
    program_id,
    token,
    recaptcha_token: !recaptchaDisable ? recaptcha_token : undefined
  });
}


export function getUser(token: string, lng?: string) {
  return axios.get<ResponseModel & { userData: UserModel } & { accessToken: string }>(GET_USER + addLng(lng), { headers: { Authorization: token } });
}
export function setUser(token: string, userData: UserModel & { password?: string, confirm_password?: string }, lng?: string, programId: string = '1') {
  let data = { ...userData };
  delete data.id;
  return axios.patch<ResponseModel & { user: UserModel } & { accessToken: string }>(SET_USER + addLng(lng),
    {
      ...data,
      program_id: programId
    },
    { headers: { Authorization: token } });
}

export function forgotPassword(email: string, recaptcha_token: string, lng?: string) {
  return axios.post<ResponseModel & { email: string }>(REQUEST_PASSWORD_URL + addLng(lng),
    {
      email,
      program_id,
      recaptcha_token: !recaptchaDisable ? recaptcha_token : undefined
    });
}


