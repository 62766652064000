// import useWindowDimensions from "./useWindowDimensions";
import useWindowDimensions from "final-project/helpers/hooks/useWindowDimensions";


const useScreenSizeName=()=>{

    const { width } = useWindowDimensions();
    if(width<576)
    return "xs"
    else if(width<768)
    return "sm"
    else if(width<992)
    return "md"
    else if(width<1200)
    return "lg"
    else if(width<1400)
    return "xl"
    else 
    return "xxl"
     
}
export default useScreenSizeName