import React from 'react'
import { CardModel } from 'final-project/api models/CardModel';
import useCurrency from 'final-project/helpers/hooks/useCurrency';
import TransperButton from 'final-project/helpers/components/TransperButton';
import { useTranslation } from 'react-i18next';
import withCommas from 'final-project/helpers/function/withCommas';

const PaymentCard:React.FC<{card:CardModel,isActive:boolean,setActiveCard:(activeId:number,balance:string)=>void}>=({card,isActive,setActiveCard}) =>{
  const fixPaymentCard=(cardNumber: string)=>{//fixe the shown number card
    return cardNumber.replace(cardNumber,cardNumber.slice(0,6)+"******"+cardNumber.slice(-4))
  }
  const seeMoreClickHandlar=()=>{
    setActiveCard(card.id,card.balance);
  }
  const {t}=useTranslation();
  const curr=useCurrency(card.currency)
  const divWrapperClass=isActive?"bg-light-primary":"btn-bg-light"
  const captionFocusClass=isActive?"text-gray-600":"text-gray-400";
  const captionClass=`${captionFocusClass} fw-bolder fs-6 d-block `
    return (
        <div className="col-12 col-sm-6 col-lg-4 me-n2 mb-5">
        <div
          
          className={`btn ${divWrapperClass} btn-color-gray-600  pe-none px-6 py-7 text-start w-100 min-w-200px  h-175px`}
        >
          
          <span className={captionClass}>{card.Sharer_FirstName?card.Sharer_FirstName?.toUpperCase():null} {card.Sharer_LastName?.toUpperCase()}&nbsp;</span>
          <span className={captionClass}>{card.Sharer_card_nickname?card.Sharer_card_nickname?.toUpperCase():null }&nbsp;</span>{/*the &nbsp; is for keeping space when there isn`t surname*/ }
          <span className={captionClass}>{card.display_number?fixPaymentCard(card.display_number):null}&nbsp;</span>
          <div className=" h2 d-block pt-6"
          style={{color:"purple"}}>{card.balance?withCommas(card.balance):null}{curr}&nbsp;</div>
         
          {" "}
       {!isActive &&
      
        <TransperButton 
        className="px-0 float-end "
        clickHandler={seeMoreClickHandlar}>
          {t("seeMore")}
        </TransperButton>
        } 
        </div>
        
      </div>
    )
}
export default PaymentCard;