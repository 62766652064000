import React from 'react'

const ShowError:React.FC<{errorMsg:string}>=({errorMsg}) =>{
    return (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{errorMsg}</div>
        </div>
    )
}

export default ShowError
