import React,{ Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import SharedCardsHome from "final-project/app/components/SharedCardsHome";
import { FallbackView } from "final-project/helpers/components/FallbackView";

const PrivateRoutes:React.FC<{}>=()=>{
    const ProfilePageWrapper = lazy(
        () => import("final-project/app/components/profile/ProfilePageWrapper")
      );
    return <Suspense fallback={<FallbackView/>}>
    <Switch>
        <Route path="/shared-cards-home" component={SharedCardsHome}/> 
        <Route path="/profile" component={ProfilePageWrapper}/> 
        <Route >
            <Redirect to="/shared-cards-home"></Redirect>
        </Route>  
        
    </Switch>
    </Suspense>
}
export default PrivateRoutes;