import axios from "axios";

import { CardModel } from "final-project/api models/CardModel";
import ResponseModel from "final-project/api models/ResponseModel";
import CategoryModel from "final-project/api models/CategoryModel";
import { TransactionModel } from "final-project/api models/TransactionModel";
import DailyBalanceModel from "final-project/api models/DailyBalanceModel";
import addLng from "final-project/crud/helperFunctions";


const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "api";

//const getUser=`${API_URL}/shared-cards-home/cards/`;
export const GET_USER_CARDS = `${BACKEND_URL}/sharing`;
export const GET_PERIOD_CARD_TRANSACTIONS = `${BACKEND_URL}/transaction/`;


export const GET_CATEGORIES = `${BACKEND_URL}/transaction/statistics/`
export const GET_BALANCE = `${BACKEND_URL}/transaction/balance/`




export function getUserCards(token: string, lng?: string) {
  return axios.get<ResponseModel & { cards: CardModel[] } & { accessToken: string }>(GET_USER_CARDS + addLng(lng), { headers: { Authorization: token } });
}


type statisticsData = {
  transactionsNumber: number,
  categories: CategoryModel[]
}
export function getPeriodCardTrans(token: string, cardId: number, skip: number, from?: string, to?: string, period?: string, lng?: string, text_search: string = "", transaction_type?: string, timezone: string = "Europe/Paris") {
  return axios.post<ResponseModel & { transactionList?: TransactionModel[] } & { accessToken: string, skip: number, moreTransactions: boolean } & { dailyBalance?: DailyBalanceModel[] }>(GET_PERIOD_CARD_TRANSACTIONS + cardId + addLng(lng),
    {
      from,
      to,
      text_search,
      transaction_type,
      timezone,
      skip,
      period
    },
    { headers: { Authorization: token } });
}
export function getBalance(token: string, cardId: number, from?: string, to?: string, period?: string, lng?: string, timezone: string = "Europe/Paris") {//,transactions_number_neto?:number
  return axios.post<ResponseModel & { dailyBalance?: DailyBalanceModel[] }>
    (GET_BALANCE + cardId + addLng(lng),
      {
        from,
        to,
        timezone,
        period,
        // transactions_number_neto
      },
      { headers: { Authorization: token } });
}
export function getCategories(cardId: number, token: string, period?: string, lng?: string, timezone: string = "Europe/Paris") {//,transactions_number_neto?:number
  return axios.post<ResponseModel & { statisticsData?: statisticsData, accessToken: string }>
    (GET_CATEGORIES + cardId + addLng(lng),
      { period, timezone },//,transactions_number_neto
      { headers: { Authorization: token } });
}