import React from "react";
import  LightDashboardPage  from "final-project/app/components/LightDashboardPage";


const SharedCardsHome:React.FC<{}>=()=>{
   
    return (
        <LightDashboardPage/>
    
   )
}
export default SharedCardsHome;