

import { programSettingContext } from "final-project/context/ProgramSetting";
import { useContext } from "react";
import { toAbsoluteUrl } from "_start/helpers";
import imagePath from "../function/imagePath";

export function FallbackView() {
  const ctx=useContext(programSettingContext)
  return (
    <div className="splash-screen">
      <img
        src={ctx.main_page_favicon || toAbsoluteUrl(process.env.REACT_APP_LOADER_PAGE_LOGO_PATH || "")}//imagePath()
        alt="logo"
      />
      <span>Loading ...</span>
    </div>
  );
}
