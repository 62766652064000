import React, { useContext, useEffect } from "react";
import {  Link } from "react-router-dom";
import AuthRoutes from "final-project/app/routing/AuthRoutes";
import { useTranslation } from "react-i18next";
//import '../../i18n'
import { programSettingContext } from "final-project/context/ProgramSetting";
import FooterLinks from "../components/footer/FooterLinks";
import imagePath from "final-project/helpers/function/imagePath";

 function AuthPage() {
   
  useEffect(() => {
    document.body.classList.add("bg-white");
    return () => {
      document.body.classList.remove("bg-white");
    };
  }, []);
  const { t } = useTranslation();
  const {bg_img_on_login,card_main_logo_img}=useContext(programSettingContext);
  return (
    <div className="d-flex flex-column flex-root">
      <div
        className="d-flex flex-column flex-lg-row flex-column-fluid"
        id="kt_login"
      >
        {/* Aside */}
        <div className="d-flex flex-column flex-lg-row-auto bg-primary w-lg-600px pt-15 pt-lg-0">
          {/* Top */}
          <div className="d-flex flex-column-auto flex-column pt-lg-40 pt-15 text-center">
            {/* begin::Aside Logo */}
            <Link to="/" className="mb-6">
              { <img
                  alt="Logo"
                  src={card_main_logo_img}//imagePath()
                  className="h-50px"
                />}
            </Link>
            {/* end::Aside Logo */}

            {/* begin::Aside Subtitle */}
            <h3 className="fw-bolder fs-2x text-white lh-lg">
             {t("pay")}
              <br />
              {t("in complete")}
            </h3>
            {/* end::Aside Subtitle */}
          </div>

          {/* Bottom */}
          <div
            className="d-flex flex-row-fluid bgi-size-contain bgi-no-repeat bgi-position-y-bottom bgi-position-x-center min-h-350px"
            style={{
              backgroundImage: `url(${bg_img_on_login})`,//imagePath()
            }}
          ></div>
        </div>

        {/* Content */}
        <div className="login-content flex-lg-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden py-20 px-10 p-lg-7 mx-auto mw-450px w-100">
          <div className="d-flex flex-column-fluid flex-center py-10">
           <AuthRoutes/>
          </div>
          <div className="d-flex justify-content-lg-start justify-content-center align-items-center py-7 py-lg-0">
            <FooterLinks className="px-2 mx-auto mb-5"/>
          </div> 
           
        </div>
       
      </div>
      
    </div>
  );
}
export default React.memo(AuthPage);