import React, { useCallback,  useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import { Cookies } from 'react-cookie';
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { userContext } from "final-project/context/UserContextProvider";
import { login } from "final-project/crud/AuthCRUD";
import 'final-project/i18n'
import useReCaptcha from "final-project/helpers/hooks/useReCaptcha";
import { useGlobalVariables } from "final-project/helpers/hooks/useGlobalVariablests";




const initialValues = {
  email: "",
  password: "",
};

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {

  const { lng } = useGlobalVariables();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { executeRecaptcha } = useReCaptcha();
  const handleReCaptchaVerify = useCallback(async () => {//set token when verify
    if (executeRecaptcha) {
      const token = await executeRecaptcha();
      return token;
    }
    return "";
  }, [executeRecaptcha]);
  const cookies = new Cookies();
  const cookieTokenName = process.env.REACT_APP_COOKIE_TOKEN_NANE || 'veritascard_sharing_session'
  const history = useHistory();
  const ctx = useContext(userContext);
  const location = useLocation<{ message: string }>();
  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("emailInvalid"))
      .min(3, t("minimumNSymbols", { number_of_symbols: 3 }))
      .max(50, t("maximumNSymbols", { number_of_symbols: 50 }))
      .required(t("emailMissing")),
    password: Yup.string()
      .min(8, t("minimumNSymbols", { number_of_symbols: 8 }))
      .max(28, t("maximumNSymbols", { number_of_symbols: 28 }))
      .required(t("passwordRequired")),
  });


  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      const retoken = await handleReCaptchaVerify();
      setTimeout(() => {
        login(values.email, values.password, retoken, lng)
          .then(({ data: { accessToken, userData } }) => {
            setLoading(false);
            const { token, expires } = accessToken.access;
            const { id, first_name, last_name, email } = userData;
            const expiredDate = new Date(expires);            
            cookies.set(cookieTokenName, token.replace('access-token-', ''), { expires: expiredDate, path: '/', httpOnly: false })
            
            ctx.updateContext({ userDatails: { id, first_name, last_name, email }, isDetailsUpdated: false,isFetchError:false})
           
            history.replace(`/shared-cards-home`)
          })
          .catch((err) => {
            console.log(err.response?.data.message)
            setSubmitting(false);
            setLoading(false);
            if (err.response?.status === 422)
              setStatus(t("somthing went"));
            else
              setStatus(err.response?.data.message || t("somthing went"));
          })
      }
        , 0);
    },
  });


  return (

    <form
      className="form w-100"
      onSubmit={formik.handleSubmit}
      noValidate
      data-testid="loginForm"
      id="kt_login_signin_form"
    >
      {location.state ? <div className="mb-lg-15 alert alert-info">
        <div className="alert-text ">
          {location.state.message}
        </div>
      </div> : null}
      {/* begin::Title */}
      <div className="pb-lg-15">
        <h3 className="fw-bolder text-dark display-6">{t('welcomeTitle')}</h3>
      </div>
      {/* begin::Title */}

      {formik.status ? (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
      ) : null
      }

      {/* begin::Form group */}
      <div className="v-row mb-10 fv-plugins-icon-container">
        <label className="form-label fs-6 fw-bolder text-dark">{t('Email')}</label>
        <input
          data-testid="email"
          placeholder={t("Email")}
          {...formik.getFieldProps("email")}
          className={clsx(
            "form-control form-control-lg form-control-solid",
            { "is-invalid": formik.touched.email && formik.errors.email },
            {
              "is-valid": formik.touched.email && !formik.errors.email,
            }
          )}
          type="email"
          name="email"
          autoComplete="off"

        />
        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{formik.errors.email}</div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="fv-row mb-10 fv-plugins-icon-container">
        <div className="d-flex justify-content-between mt-n5">
          <label className="form-label fs-6 fw-bolder text-dark pt-5">
            {t('Password')}
          </label>

          <Link
            to="/auth/forgot-password"
            className="text-primary fs-6 fw-bolder text-hover-primary pt-5"
            id="kt_login_signin_form_password_reset_button"
          >
            {t('forgotPassword')}
          </Link>
        </div>
        <input
          data-testid="password"
          type="password"
          autoComplete="off"
          placeholder={t('Password')}
          {...formik.getFieldProps("password")}
          className={clsx(
            "form-control form-control-lg form-control-solid",
            {
              "is-invalid": formik.touched.password && formik.errors.password,
            },
            {
              "is-valid": formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{formik.errors.password}</div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className="pb-lg-0 pb-5">
        <button
          type="submit"
          id="kt_login_signin_form_submit_button"
          className="btn btn-primary fw-bolder fs-6 px-8 py-4 my-3 me-3"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className="indicator-label">{t('sign in')}</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              {t('please wait...')}{" "}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>

  );
}
