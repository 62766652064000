/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useState } from "react";
import  StatsWidget6  from "final-project/app/components/cards/Cards";
import { KTSVG } from "_start/helpers";
import CardTransManager from "final-project/app/components/CardTransManager";
import { useTranslation } from "react-i18next";
import { DailyBalanceObjectModel, IsPeriodLoadedModel, TransObjectModel } from "final-project/api models/TransModel";
import { CardsCategoriesObjectModel, CardsDailyBalanceObjectModel, CardsIsPeriodLoadedObjectModel, CardsTransObjectModel } from "final-project/api models/CardsData";
import { CategoriesObjectModel } from "final-project/api models/CategoriesModel";
import { useImmer } from "use-immer";

 const LightDashboardPage: React.FC <{}>= () => {
  const [activeCardId,setActiveCardId]=useState(0);//current active card id
  
  const [prevCardId,setPrevCardId]=useState(0)// prev active card id
  const setActiveCard=(cardId:number,balance:string)=>{
    const activeCard=activeCardId
    setPrevCardId(activeCard)
    setActiveCardId(cardId);
    

  }
  
  const [allCardsTransactions,setAllCardsTransactions]=useImmer<CardsTransObjectModel>({})//data object, contains pairs of key values.key-the card id ,value contains object of all the card transactions which had been loaded
  const [allCardsPeriods,setAllCardsPeriods]=useImmer<CardsIsPeriodLoadedObjectModel>({})//data object ,contains pairs of key values .key-the card id , value contains a boolean array which symbolize the periods (true if has been loaded, false if not)
  const [allDailyBlance,setAllDailyBlance]=useImmer<CardsDailyBalanceObjectModel>({});
  const [allCardsCategories,setAllCardsCategories]=useImmer<CardsCategoriesObjectModel>({})
  const pushAllCardsTransactions=useCallback(
    
      (cardId:number,
        cardsTransObj:TransObjectModel,
        isPeriodArray:IsPeriodLoadedModel[],
        dailyBlanceObj:DailyBalanceObjectModel,
        categoriesObj:CategoriesObjectModel)=>{//function get card id, object of card's loaded transactions data and boolean array of card's loaded period ,the fun push the data objects
    const key=`card${cardId}`
    setAllCardsTransactions(draft=>{
      draft[key]=cardsTransObj
   
    })
    setAllCardsPeriods(draft=>{
      draft[key]=isPeriodArray
    
    })
    setAllDailyBlance(draft=>{
      draft[key]=dailyBlanceObj
     
    })
    setAllCardsCategories(draft=>{
      draft[key]=categoriesObj
    })
    },
    [],
  ) 
  
  const {t}= useTranslation();
  const cardKey=`card${activeCardId}`
 const props:{
  className: string;
  innerPadding?: string;
  activeCardId:number;
  setActiveCard:(cardId:number,balance:string)=>void
}={
  className:" card-stretch mb-5  mb-xxl-8",
  activeCardId:activeCardId,
  setActiveCard:setActiveCard
}
  return (
    <>
      {/* <div className="row mx-3 mx-lg-0  g-xxl-8"> */}
        <div className=" row g-0 g-xl-5 g-xxl-8">
          <StatsWidget6 {...props}>
            <a
              href={t("orderLink")}
              rel="noreferrer"
              target="_blank"
              className="btn btn-primary btn-sm fw-bolder fs-6 ps-4 mt-6"
            >
              {t("Boost")}{" "}
              <KTSVG
                className="svg-icon-3 me-0"
                path="/media/icons/duotone/Navigation/Up-right.svg"
              />
            </a>
          </StatsWidget6>
        </div>

        <div className="row g-0 g-xl-5 g-xxl-8">
          <CardTransManager 
          activeCardId={activeCardId}
          prevCardId={prevCardId}
          CardTransactions={allCardsTransactions[cardKey]}
          isPeriodLoadedArray={allCardsPeriods[cardKey]}
          dailyBlanceObj={allDailyBlance[cardKey]}
          CategoriesObj={allCardsCategories[cardKey]}
          pushAllCardsTransactions={pushAllCardsTransactions}
          />
         
          
        </div>
      {/* </div> */}
     
    </>
  );
};
export default React.memo(LightDashboardPage);