/* eslint-disable jsx-a11y/anchor-is-valid */
import { useTranslation } from "react-i18next";
import { useTheme } from "_start/layout/core";
import FooterLinks from "./FooterLinks";


export function Footer() {
  const { t } = useTranslation();
  const { classes } = useTheme();
 
  return (
    <div className={`footer py-4 d-flex flex-lg-column`} id="kt_footer">
      {/* begin::Container */}
      <div
        className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        {/* begin::Copyright */}
        <div className="text-dark order-2 order-md-1">
          <span className="text-muted fw-bold me-2">
            2010-{new Date().getFullYear()} 
          </span>
          <span  className="text-gray-800 ">
          {/* text-hover-primary */}
          
         
           {t("VeritasCard")} &copy;{t("AllRights")}
          </span>
        </div>
        {/* end::Copyright */}

        {/* begin::Nav */}
       <FooterLinks/>
        {/* end::Nav */}
      </div>
      {/* end::Container */}
    </div>
  );
}
