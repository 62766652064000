import React, { useContext} from 'react'

import { TransactionModel } from 'final-project/api models/TransactionModel'
import Transaction from 'final-project/app/components/Card Activity/Transaction'
import useCurrency from 'final-project/helpers/hooks/useCurrency'
import { currenciesContext } from 'final-project/context/CurrenciesProvider'
import useScreenSizeName from 'final-project/helpers/hooks/useScreenSizename'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import  { sortTransByFullDate } from 'final-project/helpers/function/sortTransByDate'
import Spiner from 'final-project/helpers/components/Spiner'

type Props={
    trans:TransactionModel[],
    color:string,
    showMoreTrans:()=>void,
    loadMore:boolean,
    periodId:number,
    isLoading:boolean,
}

const TransactionTable: React.FC<Props>=({trans,color,showMoreTrans,loadMore,periodId,isLoading})=> {
  let transObj:{[key:string]:TransactionModel[]}={}
  trans.sort(sortTransByFullDate);
  for(let i=0;i<trans.length;) {
    const date=moment(trans[i].FullDate).format("LL");
    transObj[date]=[]
    while(i<trans.length && date===moment(trans[i].FullDate).format("LL")  )
    {
      transObj[date].push({...trans[i]})
      i++
    }
  }
  const colors=["primary","warning","info"];//colors of icon bg color
  const {t}=useTranslation();
   const returnTransNum=Number(process.env.REACT_APP_RETURN_TRANSACTIONS_NUMBER) || 10
  
  const screenSize=useScreenSizeName();
  const showLogo=screenSize!=="xs" && screenSize!=="sm"
  const showForeignCurr=screenSize!=="xs"
      const currCtx=useContext(currenciesContext);
      const curr=useCurrency(currCtx.currentCardCurrencyCode);
      
      
      let index=0
    return  ( 
      <>
              <div className="">
              
              {/* <div key={date} className="pe-2"> */}
             
              <div className="table-responsive px-2 ">       
          <table className="table table-borderless align-middle">
            <thead>
              <tr >
               {showLogo && <th className="p-0 w-50px"></th>}
                <th className="p-0  min-w-100px "></th>
                {/* <th className="p-0  min-w-20px"></th> */}
                <th className="p-0 w-auto min-w-100px"></th>
                {/* <th className="p-0 w-auto min-w-50px"></th> */}
                {/* <th className="p-0 min-w-50px"></th> */}
              </tr>
            </thead>
            <tbody>
              {Object.keys(transObj).map((date)=>
              
              <React.Fragment key={date}>
              <tr><td colSpan={5}> <div className={`text-muted ${!showLogo?"border-bottom border-gray-300":""}`}>{date}</div></td></tr>
              {transObj[date].map((transaction,ind)=>
              {
                index++//for choose index of bg colors array
                return <Transaction 
                      key={`trans${transaction._id}`}
                      transaction={transaction}
                      curr={curr}
                      color={colors[(index)%colors.length]}
                      chartColor={color}
                      showLogo={showLogo}
                      showForeignCurr={showForeignCurr}
                    />})}
                </React.Fragment>
              )}
              </tbody>
          </table>
          {/* </div> */}
          {!!isLoading && <Spiner/>}
      </div>
      {trans.length && periodId!==1?<div className="text-center">

      <button
          className={`btn btn-bg-light btn-active-color-${color} py-3 my-3`}
          disabled={!loadMore ||  trans.length<returnTransNum}
          onClick={showMoreTrans}
        >{loadMore &&  trans.length>=returnTransNum?t("showMoreTrans"):t("noMoreTrans")}</button>
   </div>:null}
   </div>
          
             </>
        
    )
}

export default React.memo(TransactionTable);
