import { UserModel } from "final-project/api models/UserModel";
import React from "react"
import { useCallback } from "react";
import { useImmer } from "use-immer";
//import { UserModel } from "../../app/modules/auth/models/UserModel";

type userModel= {userDatails:UserModel} & {isDetailsUpdated:boolean,isFetchError?:boolean,msg?:string}  
     


let initialUser:userModel={
    isDetailsUpdated:false,
    isFetchError:false,
    msg:"",
    userDatails:
    {
        id:"",
        first_name:"",
        last_name:"",
        email:"",
        date_of_birth:"",
        phone_area:"",
        phone_number:"",
        language:"",
        timezone:"",
        address:"",
        zip_code:"",
        city:"",
        country:"",
        civility:"",
    }
   
};
const initialUsrContext={
   ...initialUser,
   updateContext:(myUser: userModel)=>{
    },
    updateFetchError:(isError:boolean)=>{

    },
    updateMsg:(msg:string)=>{

    }
    
}

export const userContext=React.createContext(initialUsrContext);
    
    
const UserContextProvider: React.FC= (props) => {
    const [user,setUser]=useImmer(initialUser);
    const updateContext=useCallback( (myUser: userModel)=>{ 
        setUser({...myUser});
    },[])
   const updateFetchError=useCallback( (isError:boolean)=>{
        setUser(draft=>{draft.isFetchError=isError})
   },[])
   const updateMsg=useCallback( (msg:string)=>{
    setUser(draft=>{draft.msg=msg})
},[])
    let contextValue={
        ...user, 
        updateContext,
        updateFetchError,
        updateMsg
    }
    return <userContext.Provider value={contextValue}>
        {props.children}
    </userContext.Provider>
}

export default UserContextProvider; 