
import ReactDOM from "react-dom";
// Redux
// https://github.com/rt2zz/redux-persist
//import { PersistGate } from "redux-persist/integration/react";
//import { Provider } from "react-redux";
//import * as _redux from "./setup";
//import store, { persistor } from "./setup/redux/Store";
// Axios
//import axios from "axios";
// Apps
import  App  from "./app/App";
import "./_start/assets/sass/style.scss";
import { CookiesProvider } from 'react-cookie';
import UserContextProvider from "./final-project/context/UserContextProvider";
import CurrenciesProvider from "./final-project/context/CurrenciesProvider";
import ProgramSettingProvider from "./final-project/context/ProgramSetting";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";


 if(process.env.NODE_ENV==="production"){
   console.log=console.warn=console.error=()=>{};
 }


Sentry.init({
  dsn:process.env.REACT_APP_SENTRY_DSN,
});
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Start mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */

/* const mock = */ 
//_redux.mockAxios(axios);
/**
 * Inject Start interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
//_redux.setupAxios(axios, store);


ReactDOM.render(
  /* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */
  // <Provider store={store}>
    
    <CookiesProvider >

    {/* <PersistGate persistor={persistor} loading={<div>Loading...</div>}> */}
    {/* <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || " "} >   */}
      <ProgramSettingProvider>
      <UserContextProvider>
        <CurrenciesProvider>
        <BrowserRouter >
      <App  />
     </BrowserRouter>
      {/* basename={PUBLIC_URL} */}
      </CurrenciesProvider>
     
      </UserContextProvider>
      </ProgramSettingProvider>
      {/* </GoogleReCaptchaProvider> */}
      {/* </PersistGate> */}
    </CookiesProvider>
  // </Provider>,
  ,
  document.getElementById("root")
);
