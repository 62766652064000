/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {  useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { Link, useHistory, useLocation} from "react-router-dom";
import { Trans,useTranslation } from "react-i18next";
import 'final-project/i18n'
import { definePassword, resetPassword } from "final-project/crud/AuthCRUD";
import { useGlobalVariables } from "final-project/helpers/hooks/useGlobalVariablests";
import useReCaptcha from "final-project/helpers/hooks/useReCaptcha";
import { Cookies } from "react-cookie";
const initialValues = {
  password: "",
  changepassword: "",
};



export default function Registration() {


  const { t ,i18n} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState<boolean | undefined>(undefined);
  const {search,pathname}=useLocation();
  const history=useHistory();
  const {lng}=useGlobalVariables();
    const paramsObj=new URLSearchParams(search);
    const email= paramsObj.get("email");
    const token=paramsObj.get("token") || " ";
    const language=paramsObj.get("lng");
    const cookies=new Cookies();
    const cookieTokenName=process.env.REACT_APP_COOKIE_TOKEN_NANE || 'veritascard_sharing_session'
    useEffect(() => {
      language && i18n.changeLanguage(language);
    },[language])
    
   const isInitializePassword=pathname.includes("initialize-password")
  const {executeRecaptcha}=useReCaptcha();
  const handleReCaptchaVerify = useCallback(async () => {
    if(executeRecaptcha){
      const token = await executeRecaptcha();
      return token;
    }
    return "";
  }, [executeRecaptcha]);
  
  const resetPassswordSchema = Yup.object().shape({
    password: Yup.string()
    .min(8, t("minimumNSymbols",{number_of_symbols:8}))
    .max(28, t("maximumNSymbols",{number_of_symbols:28}))
    .required(t("passwordRequired"))
      .matches(/(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])/,t("passwordTerms")),
    changepassword: Yup.string()
      .required(t("passwordConfirmationRequired"))
      .when("password", {
        is: (val: string) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          t("passwordAndConfirmPasswordDidNotMatch")
        ),
      }),
   
  });

  const formik = useFormik({
    initialValues,
    validationSchema: resetPassswordSchema,
    
    onSubmit: async(values, { setStatus, setSubmitting }) => {
     const recaptchaToken= await handleReCaptchaVerify();
      setLoading(true);
      setTimeout(() => {
        isInitializePassword?
       resetPassword(
        email || "",
        values.password,
        values.changepassword,
        token,
        recaptchaToken,
        lng
      )
        .then(({ data }) => {
          setLoading(false);
          setSuccess(true)
          history.push("/auth/login",{message:data.message})
        })
        .catch((err) => {
          setLoading(false);
          setSubmitting(false);
          if(err.response?.status===409)
            history.push("/auth/login",{message:err.response?.data.message || t("somthing went")})
          else
            setStatus(err.response?.data.message || t("somthing went"));
        })
        :definePassword(
          email || "",
          values.password,
          values.changepassword,
          token,
          recaptchaToken,
          lng
        )
          .then(({ data }) => {
            setLoading(false);
            setSuccess(true);
            cookies.set(cookieTokenName, "",{path:"/"})
            history.push("/auth/login",{message:data.message})
            
          })
          .catch((err) => {
            setLoading(false);
            setSubmitting(false);

            if(err.response?.status===409)
            history.push("/auth/login",{message:err.response?.data.message || t("somthing went")})
            else
            setStatus(err.response?.data.message || t("somthing went"));
          });
      }, 1000);
    },
  });

  return (
    !!success ? (
      <div className="mb-lg-15 alert alert-info">
        <div className="alert-text ">
          <Trans components={{Link:<Link to='auth/login' />}}>
        {t("passwordDefinedMessage")}
        </Trans>
        </div>
      </div>
    ): <form
      className="form w-100"
      noValidate
      id="kt_login_signup_form"
      onSubmit={formik.handleSubmit}
    >

      {/* begin::Title */}
      <div className="pb-5 pb-lg-15">
        <h3 className="fw-bolder text-dark display-6">{t("definePassword")}</h3>
        <p className="text-muted fw-bold fs-3">
        {isInitializePassword ? t("definePasswordExpl",{email}):t("resetPasswortExp")}



       
        </p>
      </div>
      {/* end::Title */}

      {formik.status && (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
      )}

      

      {/* begin::Form group Password */}
      
      <div className="fv-row mb-5">
        <label className="form-label fs-6 fw-bolder text-dark pt-5">
        {t("Password")}
        </label>
        <input
          type="password"
          placeholder="Password"
          autoComplete="off"
          {...formik.getFieldProps("password")}
          className={clsx(
            "form-control form-control-lg form-control-solid",
            {
              "is-invalid": formik.touched.password && formik.errors.password,
            },
            {
              "is-valid": formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{formik.errors.password}</div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className="fv-row mb-10">
        <label className="form-label fs-6 fw-bolder text-dark pt-5">
        {t("confirmPassword")}
        </label>
        <input
          type="password"
          placeholder={t("passwordConfirmation")}
          autoComplete="off"
          {...formik.getFieldProps("changepassword")}
          className={clsx(
            "form-control form-control-lg form-control-solid",
            {
              "is-invalid":
                formik.touched.changepassword && formik.errors.changepassword,
            },
            {
              "is-valid":
                formik.touched.changepassword && !formik.errors.changepassword,
            }
          )}
        />
        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{formik.errors.changepassword}</div>
          </div>
        )}
      </div>
      {/* end::Form group */}

     
      {/* begin::Form group */}
      <div className="d-flex flex-wrap pb-lg-0 pb-5">
        <button
          type="submit"
          id="kt_login_signup_form_submit_button"
          className="btn btn-primary fw-bolder fs-6 px-8 py-4 my-3 me-4"
          disabled={
            formik.isSubmitting || !formik.isValid
          }
        >
          {!loading && <span className="indicator-label">{t("submit")}</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              {t("pleaseWait")}{" "}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
        <Link to="/auth/login">
          <button
            type="button"
            id="kt_login_signup_form_cancel_button"
            className="btn btn-light-primary fw-bolder fs-6 px-8 py-4 my-3"
          >
           {t("cancel")}
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  );
}
