/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";

import { toAbsoluteUrl } from "_start/helpers";
import { CardModel } from "final-project/api models/CardModel";
import { currenciesContext } from "final-project/context/CurrenciesProvider";
import {  getUserCards } from "final-project/crud/CardCRUD";
import TransperButton from "final-project/helpers/components/TransperButton";
import { useGlobalVariables } from "final-project/helpers/hooks/useGlobalVariablests";
import useScreenSizeName from "final-project/helpers/hooks/useScreenSizename";
import PaymentCard from "final-project/app/components/cards/PaymentCard";
import { Trans, useTranslation } from "react-i18next";
import ShowError from 'final-project/helpers/components/ShowError'
import Spiner from "final-project/helpers/components/Spiner";
import { useImmer } from "use-immer";


//import { KTSVG, toAbsoluteUrl } from "../../../helpers";

type Props = {
  className: string;
  innerPadding?: string;
  activeCardId:number;
  setActiveCard:(cardId:number,balance:string) => void;
};

const StatsWidget6: React.FC<Props> = ({
  className,
  innerPadding = "",
  activeCardId,
  setActiveCard,
  children
}) => {
  const currenciesCtx=useContext(currenciesContext)
  const [error,setError]=useState<string | null>(null)
const [cards,setCards]=useImmer<CardModel[]>([]);
const [isLoading, setIsLoading]= useState(false);
const screenSize = useScreenSizeName();
let numOfCardsToShow=screenSize==="md"?4:3;//number of card to show according to the screen size
let [currentCardNum,setCurrentCardNum]=useState(0);
const {token,lng}=useGlobalVariables();
const {t}=useTranslation();
useEffect(()=>{
  setIsLoading(true)
  error && setError(null)
  token && getUserCards(token,lng)
    .then(async({data})=>{
     
      setCards(draft=>{//updates the cards
        draft.push(...data.cards)
        // return [
        //   ...prevCards,
        //   ...data.cards
        // ]
      })
      const newCardsArray=[...cards,...data.cards];
      setActiveCard(newCardsArray[currentCardNum].id,newCardsArray[currentCardNum].balance)//set active card id to first of the shown cards  
      currenciesCtx.updateCurrentCardCurrency(newCardsArray[currentCardNum].currency)
      
    })
    .catch(err=>{
      console.log(err.response?.data.message)
      setError(err.response?.data.message || t("somthing went"));
    })
    .finally(()=>{
      setIsLoading(false);
    })
},[])
useEffect(() => {
    cards.length && setActiveCard(cards[currentCardNum].id,cards[currentCardNum].balance)//set active card id to first of the shown cards
 
    
},[numOfCardsToShow,currentCardNum])
  useEffect(() => {
    currentCardNum%numOfCardsToShow!==0 && setCurrentCardNum(prev=>{
      return Math.floor(currentCardNum/numOfCardsToShow)*numOfCardsToShow
    })
  },[screenSize])//not sure!! set the showns cards when resize
const clickHandlerForward=()=>{
  setCurrentCardNum(prev=>prev+numOfCardsToShow)
   
}
const clickHandlerBackward=()=>{
  setCurrentCardNum(prev=>prev-numOfCardsToShow)
}

  return (
   <div className="col-12">
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className={`card-body ${innerPadding}`}>
        {/* begin::Top */}
        <div className="d-flex bg-light-primary card-rounded flex-grow-1">
          {/* begin::Section */}
          <div className="py-10 px-7">
            {/* begin::Text */}
            <div className="">
              <span className="text-primary d-block mb-n1">{t("withVeritas")}</span>
              <span className="font-weight-light fs-1 text-gray-800">
                <Trans components={{b:<span className="fw-bolder fs-1 text-gray-800"/>}}>
                  {t("BoostYourFinancialActivity")}
                </Trans>
              {/* {t("BoostYour")}{" "}
                <span className="fw-bolder fs-1 text-gray-800">{t("finantial")}{" "}</span>
                {t("activity")} */}
              </span>
            </div>
            
            {/* end::Text */}
            {children}
          </div>
          {/* end::Section */}

          {/* begin::Pic */}
          <div
            className="position-relative bgi-no-repeat bgi-size-contain bgi-position-y-bottom bgi-position-x-end mt-6 flex-grow-1"
            style={{
              backgroundImage: `url('${toAbsoluteUrl(
                "/media/misc/illustration-1.png"
              )}')`,
            }}
          ></div>
          {/* end::Pic */}
        </div>
        {/* end::Top */}

        {/* begin::Stats */}
        <div className="pt-9">
          {/* begin::Row */}
          <div className="row">
            {error?<ShowError errorMsg={error}/>:
          !isLoading ?  <>
          {cards.length>0 ? Array.from( Array(Math.min(numOfCardsToShow,cards.length-currentCardNum)) ,(v,k)=>{//loop to show the number of cards (loop the min between the num of card that I can show and the num of remaining cards)
             return !!cards[currentCardNum+k] && <PaymentCard 
             key={cards[currentCardNum+k].id} 
             card={cards[currentCardNum+k]} 
             isActive={cards[currentCardNum+k].id===activeCardId}
             setActiveCard={setActiveCard}/>
           }):<div className="col-12 fw-bolder py-5 fs-1 text-gray-800 text-center">{t("noValidCardsFound")}</div>}
           </>:
          <Spiner/>}
          
            {/* <div className="col mb-5"> */}
              {/* <button
                type="button"
                className="btn btn-outline btn-bg-light btn-color-gray-600 btn-active-light-primary border-dashed border-active px-6 py-7 text-start w-100 min-w-150px"
              >
                <KTSVG
                  className="svg-icon-2x ms-n1"
                  path="/media/icons/duotone/General/Clipboard.svg"
                />{" "}
                <span className="text-gray-800 fw-bolder fs-6 d-block pt-6">
                  Read Docs
                </span>
              </button> */}
            {/* </div> */}
          </div>
        </div>
        <div className="">
        {cards.length>(currentCardNum+numOfCardsToShow) && //is I have more cards to show
        <TransperButton 
        className="float-end"
        clickHandler={clickHandlerForward}>
         {t("showMore",{num_of_cards:cards.length-(currentCardNum+numOfCardsToShow)})}
        </TransperButton>
       }

        {(currentCardNum+1)>=numOfCardsToShow && //is I have back cards
        <TransperButton 
          className="float-start"
          clickHandler={clickHandlerBackward}
        >
          {t("backward")}
        </TransperButton>}
        </div>
        {/* end::Stats */}
      </div>
      {/* end::Body */}
    </div>
    </div>
  );
};

export default React.memo(StatsWidget6);


