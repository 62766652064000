import React, { useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { ThemeProvider } from "_start/layout/core";
import PrivateRoutes from "final-project/app/routing/PrivateRoutes";
import { useEffect } from "react";
import { useContext } from "react";
import { userContext } from "final-project/context/UserContextProvider";
import { getUser } from "final-project/crud/AuthCRUD";
import { MasterLayout } from "final-project/app/components/MasterLayout";
import { useTranslation } from 'react-i18next';
import { useGlobalVariables } from "final-project/helpers/hooks/useGlobalVariablests";
import AuthPage from "final-project/app/auth/AuthPage";
import { Helmet } from 'react-helmet'
import { FallbackView } from "final-project/helpers/components/FallbackView";
import { programSettingContext } from "final-project/context/ProgramSetting";
import { toAbsoluteUrl } from "_start/helpers";
import moment from "moment";


type Props = {
  basename?: string;
};

const App: React.FC<Props> = ({ basename }) => {
  
    const { token, lng } = useGlobalVariables();
  const ctx = useContext(userContext);
  const programCtx = useContext(programSettingContext);
  const { t, i18n } = useTranslation();
  const [isAuth, setIsAuth] = useState<boolean>(!!token && !!ctx.userDatails.id);
  const [isLoading, setIsloading] = useState<boolean>(true)
  const location = useLocation();
  const langForImport = lng ? lng.substring(0, lng.indexOf("-")) : undefined;
  useEffect(() => {
    if (token && ctx.userDatails.email) {
      setIsAuth(true)
    }
    if (token && !location.pathname.includes("define-password")) {//login the user stragith with the token if the url is not define-password
      !ctx.isDetailsUpdated && getUser(token, lng).then(({ data }) => {
        ctx.updateContext(
          {
            isDetailsUpdated: true,
            userDatails: { ...data.userData }
          })
        setIsAuth(true)
      }).catch(err => {
        console.log(err.response?.data.message || t("somthing went"))
      }).finally(() => {
        setIsloading(false);
      })
    }
    else {
      setIsAuth(false);
      setIsloading(false);
    }
    if (ctx.userDatails.language && ctx.userDatails.language !== lng) {
      i18n.changeLanguage(ctx.userDatails.language)
      localStorage.setItem(process.env.REACT_APP_SESSIONSTORAGE_LNG_NAME || "i18nextLng", ctx.userDatails.language)
    }

  }, [token, ctx.userDatails.id, ctx.userDatails.first_name, ctx.userDatails.last_name, ctx.userDatails.language])
  useEffect(() => {
    if (lng) {
      const EnLngForImport = langForImport !== "en" ? langForImport : "en-au";
      import(`moment/locale/${EnLngForImport}`)
        .then(() => {
          moment.locale(EnLngForImport)
        })
        .catch((err)=>{
          console.log("error while importing moment locale",err);
        })
    }
  }, [lng])
  return (
    <>
      <Helmet htmlAttributes={{ lang: langForImport }}>
        <meta name="description" content={t("metaDescription")} />
        <title>{t("Veritas Mastercard")}</title>
        <link rel="shortcut icon" href={programCtx.main_page_favicon || toAbsoluteUrl(process.env.REACT_APP_HEADER_LOGO_PATH || "")} />{/* imagePath() */}
      </Helmet>

      {
        !isLoading ?
          <ThemeProvider>
            <Switch>
              {/* <Route path="/error" component={ErrorsPage} /> */}
              {/* <Route path="/logout" component={Logout} /> */}
              {!isAuth ? (
                <Route>

                  <AuthPage />

                  {/* <PublicRoutes /> */}
                </Route>
              ) : (
                <>
                  <MasterLayout>
                    <PrivateRoutes />

                  </MasterLayout>
                </>
              )}
            </Switch>
          </ThemeProvider>
          /* <Switch>
           {!isAuthorized ? (
                 <Route>
                   <PublicRoutes />
                 </Route>
               ) : (
                 <>
                  
                  <MasterLayout>
                     <PrivateRoutes />
                   </MasterLayout>
                   
                 </>
               )}
           </Switch> */
          : <FallbackView />}
    </>
  );
};

export default React.memo(App);
