
import {  toAbsoluteUrl } from "_start/helpers"
import { TransactionModel } from "final-project/api models/TransactionModel"
import useCurrency from "final-project/helpers/hooks/useCurrency"
import { useTranslation } from "react-i18next"
import withCommas from "final-project/helpers/function/withCommas"
import moment from "moment"
import React from "react"

type Props={
    transaction:TransactionModel,
    curr:string | null,
    color:string ,
    chartColor:string
    showLogo:boolean,
    showForeignCurr:boolean,
}

const Transaction:React.FC<Props> = (
    { 
        transaction,
        curr,
        color,
        chartColor,
        showLogo,
        showForeignCurr
    }
) => {
     const {t}= useTranslation();
     const foreignCurr=useCurrency(transaction.termcurrency);
     const minusIfNeg=transaction.amount.trim().startsWith("-")?"-":""
    
    return (
            <>
               <tr>
                   {showLogo && <td className="px-0 py-3">
                      <div className={`symbol symbol-50px symbol-light me-5`}>
                     <span className={`symbol-label bg-light-${color}`}>
                        <img
                          // style={{backgroundColor:getCSSVariableValue(color)}}
                          src={transaction.logo?toAbsoluteUrl(
                            `/media/icons/duotone/Transactions/${transaction.logo}`
                          ):toAbsoluteUrl("/media/defualt logos/shopping-bag.png")}
                          alt=""
                          className="mw-75"
                        />
                      </span>
                      </div>
                    </td>}
                    <td className="px-0">
                       <div
                        //href="#"
                        className="fs-6 text-gray-800 fw-bolder"
                      >
                        {transaction.termnamelocation}
                      </div>
                      <span className="text-muted fw-bold d-block mt-1">
                          {transaction.description}
                      </span>
                      <span className="text-muted  d-block mt-1">
                          {moment(transaction.FullDate).utc().format("LL HH:mm")}
                      </span>
                     
                    </td>
                    {/* <td></td> */}
                   {showForeignCurr && <td className="text-end ">
                      {transaction.isOriginalAmountDiff && <span className={`fw-bolder text-${chartColor}`}>
                        {minusIfNeg}{withCommas(transaction.origtransamt)}{foreignCurr}
                        </span>}
                     
                    </td>}
                    {/* <td>
                   
                    </td> */}
                    <td className="text-end">
                      <span className="d-block mb-3">
                      <span className="bg-light rounded fw-bolder text-gray-600 py-2 my-2 px-2">
                     {withCommas(transaction.amount)}{curr}
                      </span> 
                      </span>
                      {Number(transaction.fee) ?<span className="text-muted fw-bold d-block mt-1 fs-7 px-2">
                      {transaction.fee?withCommas(transaction.fee):""}{curr} ({t("fee")})
                      </span>:null}
                      <span className="text-muted fw-bold d-block mt-1 fs-7 px-2">
                      {transaction.isStatusApproved?t("Approved") :transaction.isStatusPending && t("PendingApproval")}
                      {transaction.rspcode!=="000"? t("failed"):null}
                      </span>
                      
                    </td>
                   
                    {/* <td className="text-end pe-0">
                      <a className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                        <KTSVG
                          className="svg-icon-4"
                          path="/media/icons/duotone/Navigation/Arrow-right.svg"
                        />
                      </a>
                    </td> */}
                  </tr>
                </>
         
    )
}

export default React.memo(Transaction)
