import React from "react";


import { AsideDefault } from "_start/layout/components/aside/AsideDefault";
import { Content } from "_start/layout/components/Content";

import { ScrollTop } from "_start/layout/components/ScrollTop";

import { PageDataProvider } from "_start/layout/core";
import { MasterInit } from "_start/layout/MasterInit";
import { HeaderWrapper } from "final-project/app/components/header/HeaderWrapper";
import { Footer } from "final-project/app/components/footer/Footer";



const MasterLayout: React.FC = ({ children }) => {
  return (
    <PageDataProvider>
  
  {/* 1 */}
  <div className="d-flex flex-column flex-root">
    
  {/* 2 */} 
  <div className="page d-flex flex-row flex-column-fluid">
      <AsideDefault />
    
      {/* 3 */}
      <div
        className="wrapper d-flex flex-column flex-row-fluid mx-0"
        id="kt_wrapper"
      >
        <HeaderWrapper />
        {/* 4 */}
        <div className="d-flex flex-column flex-column-fluid">
          {/* <Toolbar /> */}
          {/* 5 */}
          <div
            className="content pt-0 fs-6 d-flex flex-column-fluid"
            id="kt_content"
          >
            <Content>{children}</Content>
        {/* 5 */} 
        </div>
      {/* 4 */} 
      </div>
        <Footer />
    {/* 3 */} 
    </div>
      {/* <Sidebar /> */}
    {/* 2 */}
    </div>
  {/* 1 */}
  </div>
<ScrollTop />
<MasterInit />
</PageDataProvider>
  );
};

export { MasterLayout };
