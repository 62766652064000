import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

import i18n from "i18next";
import en from 'final-project/lngs/en.json'
import fr from 'final-project/lngs/fr.json'


export const resources = {
  en: {
    translation:en
  },
  fr:{
    translation:fr

  }
} 

const fun=async()=>{

await i18n
.use(initReactI18next)
.use(LanguageDetector)
.use(HttpApi)

.init({
  
 detection:{
  order: [ 'querystring', 'path', 'subdomain', 'htmlTag','sessionStorage','localStorage','cookie','navigator' ],

  // keys or params to lookup language from
  lookupQuerystring: 'lng',
  lookupCookie: process.env.REACT_APP_SESSIONSTORAGE_LNG_NAME || "i18nextLng",
  lookupLocalStorage: 'i18nextLng',
  lookupSessionStorage: process.env.REACT_APP_SESSIONSTORAGE_LNG_NAME || "i18nextLng",
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: [],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
  htmlTag: document.documentElement,

  // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
  //cookieOptions: { path: '/', sameSite: 'strict' }
 },
  //lng: 'en',
  fallbackLng: "en-US",
 react:{
  wait: true,
  useSuspense: false,
 },
  supportedLngs: [ 'en-US', 'fr-FR'],
  interpolation: {
  escapeValue: false
},
// resources,
backend:{
  
  loadPath:`${process.env.REACT_APP_BACKEND_URL}/program/translation?program_id=1&lng={{lng}}`,
  parse:function(data:any,lng) {
   return JSON.parse(data).translation; },  
  
},

},(err,t)=>{
  if(err)
  console.log("i18nerr",err);
  
  
})
}
fun()
