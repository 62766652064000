/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { programSettingContext } from "final-project/context/ProgramSetting";
import imagePath from "final-project/helpers/function/imagePath";
import React, { useContext, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap-v5";
import { Trans, useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
//import { KTSVG, toAbsoluteUrl } from "_start/helpers";
import MenuItem from "final-project/app/components/menu/MenuItem";
import { KTSVG } from "_start/helpers/components/KTSVG";
import { toAbsoluteUrl } from "_start/helpers";

//import { KTSVG, toAbsoluteUrl } from "../../helpers";
type Props = {
  show: boolean;
  handleClose: () => void;
};

const MenuModal: React.FC<Props> = ({ show, handleClose, children }) => {
  const location = useLocation();
  const isFirstRef = useRef(true);
  const {t}=useTranslation();
  const menuArray:{key:string,link:string,title:string,text:string}[]=t("menu",{returnObjects:true}) || [];    
  const programCtx=useContext(programSettingContext);
  const colorsArray=["success","danger","warning","warning","success"];
  const menuItemsInfirstRow=3
  useEffect(() => {
    if (isFirstRef.current) {
      isFirstRef.current = false;
    } else {
      handleClose();
    }
  }, [location]);

  return (
    <Modal
      className="bg-white"
      id="kt_mega_menu_modal"
      aria-hidden="true"
      tabIndex="-1"
      dialogClassName="modal-fullscreen"
      contentClassName="shadow-none"
      show={show}
    >
      <div className="container" key="123456">
        <div className="modal-header d-flex align-items-center justify-content-between border-0">
          <div className="d-flex align-items-center">
            {/* begin::Logo */}
            <Link to="/">
              <img
                alt="logo"
                className="h-30px"
                src={programCtx.card_main_logo_img}//imagePath()
              />
            </Link>
            {/* end::Logo */}
          </div>

          {/* begin::Close */}
          <div
            className="btn btn-icon btn-sm btn-light-primary ms-2"
            onClick={handleClose}
          >
            <KTSVG
              path="/media/icons/duotone/Navigation/Close.svg"
              className="svg-icon-2"
            />
          </div>
          {/* end::Close */}
        </div>
        <div className="modal-body">
          {/* begin::Row */}
          <div className="row py-10 g-5">
            {/* begin::Column */}
            {/* <div className="col-lg-6 pe-lg-25">{children}</div> */}
            {/* end::Column */}

            {/* begin::Column */}
            <div className="col-lg-6">
              {/* <h3 className="fw-bolder mb-8">Quick Links</h3> */}

              {/* begin::Row */}
              <div className="row g-5">
              { Array.from(new Array(menuItemsInfirstRow)).map((val,index)=> 
               <MenuItem
                key={"MenuItem"+menuArray[index].key}
                link={menuArray[index].link}
                title={menuArray[index].title}
                text={menuArray[index].text}
                color={colorsArray[index]}
                col={4}
              />)}
                {/* <div className="col-sm-4">
                  <a
                    href="#"
                    className="card card-custom bg-light-danger hoverable min-h-125px shadow-none mb-5"
                  >
                    <div className="card-body d-flex flex-column flex-center text-center">
                      <h3 className="fs-3 mb-2 text-dark fw-bolder">Demo</h3>
                      <p className="mb-0 text-gray-600">Free Version</p>
                    </div>
                  </a>
                </div>
                <div className="col-sm-4">
                  <a
                    href="#"
                    className="card card-custom bg-light-warning hoverable min-h-125px shadow-none mb-5"
                  >
                    <div className="card-body d-flex flex-column flex-center text-center">
                      <h3 className="fs-3 mb-2 text-dark text-hover-primary fw-bolder">
                        Try Now
                      </h3>
                      <p className="mb-0 text-gray-600">Pro Version</p>
                    </div>
                  </a>
                </div>
              */}
             </div>
              {/* end::Row */}

              {/* begin::Row */}
              <div className="row g-5">
              <div className="col-sm-8">
               { menuArray[3]?  <a
                rel="noreferrer"
                  target="_blank"
                    href={menuArray[3].link}
                    className="card card-custom bg-light-primary hoverable min-h-125px shadow-none mb-5"
                  >
                    <div className="card-body d-flex flex-column flex-center text-center">
                      <h3 className="fs-3 mb-2 text-dark fw-bolder">
                      {menuArray[3].title}
                      </h3>
                      <p className="mb-0 text-gray-600">
                        <Trans components={{br:<br/>}}>
                        {/* Credit Cards/Debit Cards, Paypal,
                        <br /> Transferwise & Others */}
                         {menuArray[3].text}
                        </Trans>
                      </p>
                    </div>
                  </a>:null}

                  {/* begin::Row */}
                  <div className="row g-5">
                  { Array.from(new Array(2)).map((val,index)=> menuArray[menuItemsInfirstRow+index+2]?<MenuItem
                key={"MenuItem"+menuArray[menuItemsInfirstRow+index+2].key}
                link={menuArray[menuItemsInfirstRow+index+2].link}
                title={menuArray[menuItemsInfirstRow+index+2].title}
                text={menuArray[menuItemsInfirstRow+index+2].text}
                color={colorsArray[menuItemsInfirstRow+index]}
                col={6}
              />:null)}
                    {/* <div className="col-sm-6">
                      <a className="card card-custom bg-light-warning hoverable shadow-none min-h-125px mb-5">
                        <div className="card-body d-flex flex-column flex-center text-center">
                          <h3 className="fs-3 mb-2 text-dark fw-bolder">
                            Support
                          </h3>
                          <p className="mb-0 text-gray-600">6 Month Free</p>
                        </div>
                      </a>
                    </div>
                    <div className="col-sm-6">
                      <a
                        href="#"
                        className="card card-custom bg-light-success hoverable shadow-none min-h-125px mb-5"
                      >
                        <div className="card-body d-flex flex-column flex-center text-center">
                          <h3 className="fs-3 mb-2 text-dark fw-bolder">
                            Installation
                          </h3>
                          <p className="mb-0 text-gray-600">
                            $0.99 Per Machine
                          </p>
                        </div>
                      </a>
                    </div> */}
                  </div>
                  {/* end::Row */}
                </div>
                <div className="col-sm-4">
                 {menuArray[4]? <a
                 rel="noreferrer"
                  target="_blank"
                    href={menuArray[4].link}
                    className="card card-custom card-stretch mb-5 bg-light-info hoverable shadow-none min-h-250px"
                  >
                    <div className="card-body d-flex flex-column p-0">
                      <div className="d-flex flex-column flex-center text-center px-5 pt-10">
                        <h3 className="fs-3 mb-2 text-dark fw-bolder">
                        {menuArray[4].title}
                        </h3>
                        <p className="mb-0 text-gray-600">
                        {menuArray[4].text}
                        </p>
                      </div>
                      <div
                        className="flex-grow-1 bgi-no-repeat bgi-size-contain bgi-position-x-center bgi-position-y-bottom card-rounded-bottom"
                        style={{
                          backgroundImage: `url('${toAbsoluteUrl(
                            "/media/illustrations/terms-1.png"
                          )}')`,
                        }}
                      />
                    </div>
                  </a>:null}
                </div>
              </div>
              {/* end::Row */}
            </div>
            {/* end::Column */}
          </div>
          {/* end::Row */}
        </div>
      </div>
    </Modal>
  );
};

export { MenuModal };
