import axios from "axios";
// import ResponseModel from "../api models/ResponseModel";
import ResponseModel from "final-project/api models/ResponseModel";
import addLng from "final-project/crud/helperFunctions";


const API_URL = process.env.REACT_APP_BACKEND_URL || "api";


const GET_PROGRAM_SETTING=`${API_URL}/program/settings`


type setting={
    id: number,
    name: string,
    address: string,
    main_page_document_title: string,
    main_page_favicon: string,
    logo_img_on_login: string,
    bg_img_on_login: string,
    logo_img_on_shared_cards: string,
    card_main_logo_img: string,
    default_card_type_logo_img: string,
    transactionTypeOptions: 
      {
        id: number,
        program_id: number,
        label:string,
        options: {value: string}[],
        createdAt: string,
        updatedAt: string
      }[], 
      
    
}



export function getProgramSetting(lng?:string,program_id:number=1) {
    return axios.get<ResponseModel & {settings:setting}>(`${GET_PROGRAM_SETTING}?program_id=${program_id}${addLng(lng)}`);
  }