import React from 'react'
import { PeriodModel } from 'final-project/api models/PeriodModel'

type Props={
    period?:PeriodModel;
    clickHandler:any, 
    isActive:boolean,

}
const PeriodButton:React.FC<Props>=({
     children,
     period,
     clickHandler,
     isActive
    })=> {
    return (
        <li className="nav-item">
        <button
          onClick={e=>{clickHandler(period || true)}}
          className={`nav-link btn btn-active-light btn-color-muted py-2 px-4 fw-bolder me-2 mb-3 ${isActive && 'active'}`}
          data-bs-toggle="tab"
          //href="#kt_tab_pane_1_1"
        >
          {children}
        </button>
      </li>
    )
}

export default PeriodButton
