import React, { useCallback, useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link} from "react-router-dom";
import { useFormik } from "formik";
// import { forgotPassword } from "../../crud/AuthCRUD";
import { forgotPassword } from "final-project/crud/AuthCRUD";
//import { requestPassword } from "../../../app/modules/auth/redux/AuthCRUD";
import { useTranslation } from "react-i18next";
//import '../../i18n'
import 'final-project/i18n'
import useReCaptcha from "final-project/helpers/hooks/useReCaptcha";
import { useGlobalVariables } from "final-project/helpers/hooks/useGlobalVariablests";
const initialValues = {
  email: "",
};



export function ForgotPassword() {
  const {lng}=useGlobalVariables();
  const { t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const {executeRecaptcha}=useReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if(executeRecaptcha){
      const token = await executeRecaptcha();
      return token;
    }
    return "";
  }, [executeRecaptcha]);
  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
    .email(t("emailInvalid"))
    .min(3, t("minimumNSymbols",{number_of_symbols:3}))
    .max(50, t("maximumNSymbols",{number_of_symbols:50}))
    .required(t("emailMissing")),
  });



  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async(values, { setStatus, setSubmitting }) => {
      const recaptchaToken=await handleReCaptchaVerify();
      setLoading(true);
      setHasErrors(undefined);
      setTimeout(() => {
        forgotPassword(values.email,recaptchaToken,lng)
          .then(({ data }) => {
            setHasErrors(false);
            setLoading(false);
            
          })
          .catch((err) => {
            setHasErrors(true);
            setLoading(false);
            setSubmitting(false);
            setStatus(err.response?.data.message || t("somthing went"));
          });
      }, 1000);
    },
  });

  return (
    <>
     {hasErrors===false ?<div className="mb-lg-15 alert alert-info">
            <div className="alert-text ">
            {t("Please check your email for further instructions.")}
            </div>
          </div>:<form
        className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
        noValidate
        id="kt_login_password_reset_form"
        onSubmit={formik.handleSubmit}
      >
        <div className="pb-5 pb-lg-10">
          <h3 className="fw-bolder text-dark display-6">
          {t("forgotPassword")}
          </h3>
          <p className="text-muted fw-bold fs-3">
          {t("forgotPasswordMessage")}
          </p>
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">
            {t("somthing went")}
            </div>
          </div>
        )}

        
        {/* end::Title */}

        {/* begin::Form group */}
        <div className="fv-row mb-10">
          <label className="form-label fs-6 fw-bolder text-dark pt-5">
          {t("Email")}
          </label>
          <input
            type="email"
            placeholder={t("Email")}
            autoComplete="off"
            {...formik.getFieldProps("email")}
            className={clsx(
              "form-control form-control-lg form-control-solid",
              { "is-invalid": formik.touched.email && formik.errors.email },
              {
                "is-valid": formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className="d-flex flex-wrap pb-lg-0">
          <button
            type="submit"
            id="kt_login_password_reset_form_submit_button"
            className="btn btn-primary fw-bolder fs-6 px-8 py-4 my-3 me-4"
          >
             {t("submit")}
          </button>
          <Link to="/auth/login">
            <button
              type="button"
              id="kt_login_password_reset_form_cancel_button"
              className="btn btn-light-primary fw-bolder fs-6 px-8 py-4 my-3"
              disabled={formik.isSubmitting || !formik.isValid}
            >
               {t("cancel")}
            </button>
          </Link>{" "}
          {loading && (
            <span
              className="spinner-border text-primary ms-3 mt-6"
              role="status"
            >
              <span className="visually-hidden">{t("pleaseWait")}</span>
            </span>
          )}
        </div>
        {/* end::Form group */}
      </form>
}
    </>
  );
}
