import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import DefinePassword from "final-project/app/auth/DefinePassword";
import { ForgotPassword } from "final-project/app/auth/ForgotPassword";
import IsEmailAndToken from "final-project/app/auth/IsEmailAndToken";
import { Login } from "final-project/app/auth/Login";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { useGlobalVariables } from "final-project/helpers/hooks/useGlobalVariablests";


const AuthRoutes:React.FC<{}>=()=>{
    const {lng}=useGlobalVariables();
    return  (
    <GoogleReCaptchaProvider 
    reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || " "} 
    language={lng}  >  
    <Switch>
        <Route path="/auth/login" component={Login} />
        <Route path="/auth/forgot-password" component={ForgotPassword} />
        <Route path="/auth/define-password"> 
            <IsEmailAndToken>
                <DefinePassword/>
            </IsEmailAndToken>
        </Route> 
        <Route path="/auth/initialize-password"> 
            <IsEmailAndToken>
                <DefinePassword/>
            </IsEmailAndToken>
        </Route> 
   
    <Route>
    <Redirect to="/auth/login" />
    </Route>
    </Switch> 
    </GoogleReCaptchaProvider  > )
    
}
export default  React.memo(AuthRoutes);


